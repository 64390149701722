import axios from "axios";
import Session from "@/services/Session";
import CommonService from "@/services/CommonService";
// import store from "./store";

const api = axios.create({
	baseURL: (process.env.NODE_ENV == 'development') ? 'http://localhost/api/v2' : 'https://joywork.ru/api/v2',
	headers: {
		'Content-type': 'application/json',
		// 'Access-Token':  "Session.get('token') ?? null",
	},
	xdebug: false,
	//withCredentials: true,
	addTrailingSlash: false,
	timeout: (process.env.NODE_ENV == "development") ? 60000 : 30000,
});

if (process.env.NODE_ENV == "development" && api.defaults.xdebug == true)
	api.defaults.headers.common['X-debug'] = true;

api.interceptors.request.use((request) => {
	request.headers['Access-Token'] = Session.get('token', true) ?? null;

	if (!(request.method == "get" && request.url == "/common/events"))
		//document.body.classList.add("on-loading");

	// Adding a trailing slash automatically
	if (request.addTrailingSlash && request.url[request.url.length-1] !== '/')
		request.url += '/';

	if (process.env.NODE_ENV == "development")
		console.debug('Starting Request...', request);

	return request;
}, (error) => {

	if (process.env.NODE_ENV == "development")
		console.error('Request ERROR', error);

	// store.commit('addApiErrors', {type: 'request', error: error});

	let header = document.body.querySelector('header.header');
	if (header) {
		document.body.classList.remove("on-loading");
		document.body.classList.add("on-failure");
		header.setAttribute('data-error', error);
		setTimeout(() => {
			document.body.classList.remove("on-failure");
			header.removeAttribute('data-error');
		}, 3000);
	}

	return Promise.reject(error)
});

api.interceptors.response.use((response) => {

	if (process.env.NODE_ENV == "development")
		console.debug('Response:', response);

	/*if (!(response.request.method == "get" && response.request.url == "/common/events")) {
		if (response.data.success == false) {
			document.body.classList.remove("on-loading");
			document.body.classList.remove("on-success");
			document.body.classList.add("on-error");
			setTimeout(() => {
				document.body.classList.remove("on-error");
			}, 2000);
		} else {
			document.body.classList.remove("on-loading");
			document.body.classList.add("on-success");
			setTimeout(() => {
				document.body.classList.remove("on-success");
			}, 2000);
		}
	}*/

	return response;
}, (error) => {

	if (process.env.NODE_ENV == "development")
		console.error('Response ERROR:', error);

	const status = error.response ? error.response.status : null;
	if ((status === 401 || error == 'Error: Network Error' ) && !CommonService.inArray(window.location.pathname, ["/login", "/register", "/restore", "/status"])) {
		CommonService.debounce(() => window.location.href = '/login', 1000);
	}

	// store.commit('addApiErrors', {type: 'response', error: error});
	document.body.classList.remove("on-loading");

	let url = error.request.responseURL;
	if (!(error.request.method == "get" && error.request.url == "/common/events")) {
		let header = document.body.querySelector('header.header');
		if (error && error != 'Error: Network Error' && header) {
			document.body.classList.add("on-failure");

			if (url)
				header.setAttribute('data-error', error + " from " + url);
			else
				header.setAttribute('data-error', error);

			setTimeout(() => {
				document.body.classList.remove("on-failure");
				header.removeAttribute('data-error');
			}, 5000);
		}
	}

	return Promise.reject(error);
});

export default api;